/*
@tailwind base;
*/
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
  font-family: "outfit", sans-serif;
}
button{
  appearance: none;
  border: none;
  font-family: inherit
}
a {
  margin: 0;
  padding: 0;
}

html {
  overflow-x: hidden;
  position: relative;
}
body {
  color: #1d1d63;
  background-color: hsl(10, 0%, 90%);
  font-size: 16px;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
.fancy-header {
  font-family: "mogra", sans-serif;
}

input[type="checkbox"] {
  box-shadow: none;
  border: none;
  outline: none;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.boxed-layout {
  width: 70%;
  min-height: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  flex-flow: column wrap;
  /* align-items: center; */
  /* justify-content: center; */
  flex-grow: 1;
  @media screen and (max-width: 950px) {
    width: 100%;
  }
}

.label-name {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #a0a0a0;
  margin-bottom: 8px;
  display: block;
}
